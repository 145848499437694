import React from 'react'
import { Box } from 'grommet'

export const Block = ({ children, ...props }) => (
  <Box
    round="small"
    pad="medium"
    justify="center"
    align="center"
    minHeight="xsmall"
    margin={{ bottom: 'small', right: 'small' }}
    background="white"
    border={{ color: 'accent-3' }}
    {...props}
  >
    {children}
  </Box>
)

export const HalfBlock = ({ children, ...props }) => (
  <Block basis="1/3" flex={true} style={{ minWidth: '12rem' }} {...props}>
    {children}
  </Block>
)

export default (children, props) => Block(children, props)