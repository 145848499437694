import React from 'react'
import { Button, Box, Text } from 'grommet'
import { Apple, Android } from 'grommet-icons'

export default ({ onboarded }) => (
  <>
    {!onboarded ? (
      <Box style={{ flexDirection: 'column' }}>
        <Text
          color="accent-1"
          weight={600}
          size="medium"
          textAlign="center"
          margin={{ bottom: 'small' }}
        >
          Vitamin formula status:
        </Text>
        <Text
          size="medium"
          color="accent-4"
          textAlign="center"
          weight={600}
          margin={{ bottom: 'small' }}
        >
          personalized information required
        </Text>
        <Text
          size="small"
          color="accent-3"
          textAlign="center"
          margin={{ bottom: 'small' }}
        >
          Complete our personalization questions on the Gini App to see your
          personalized vitamin formula in the app.
        </Text>
        <Text
          size="medium"
          color="accent-2"
          textAlign="center"
          weight={600}
          margin={{ bottom: 'small' }}
        >
          Get the Gini App
        </Text>
        <Box direction="row" justify="center">
          <Button
            href="https://go.onelink.me/app/giniios"
            icon={<Apple />}
            color="accent-1"
            label="iOS"
            margin={{ vertical: 'small' }}
            style={{ alignItems: 'center', margin: 8 }}
            primary
          />
          <Button
            href="https://go.onelink.me/app/giniandroid"
            color="accent-1"
            icon={<Android />}
            label="Android"
            margin={{ vertical: 'small' }}
            style={{ alignItems: 'center', margin: 8 }}
            primary
          />
        </Box>
      </Box>
    ) : (
      <Box style={{ flexDirection: 'column' }}>
        <Text
          color="accent-1"
          weight={600}
          size="medium"
          textAlign="center"
          margin={{ bottom: 'small' }}
        >
          Vitamin formula status:
        </Text>
        <Text
          size="medium"
          color="accent-4"
          textAlign="center"
          weight={600}
          margin={{ bottom: 'small' }}
        >
          Up to date
        </Text>
        <Text
          size="small"
          color="accent-3"
          textAlign="center"
          margin={{ bottom: 'small' }}
        >
          View your formula in the Gini App under the pill tab in the top left
          corner. Track your meals on the Gini app to see your vitamin formula
          update to your most recent needs.
        </Text>
      </Box>
    )}
  </>
)
