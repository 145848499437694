import React from 'react'
import { connect } from 'react-redux'
import { Box, Heading, Text, Button } from 'grommet'
import { Link } from 'gatsby'
import { format } from 'date-fns'
import AppLayout from '../components/app-layout'
import { registerKit } from '../actions/register'
import Popup from "reactjs-popup"

import { Block } from '../components/pages/index/blocks'
import VitaminFormula from '../components/pages/index/vitamin-formula'
import DnaData from '../components/pages/index/dna-data'
import TestKits from '../components/pages/index/test-kits'

const FIELDS = [
  { id: 'email', label: 'Email' },
  { id: 'sex', label: 'Sex' },
  { id: 'dob', label: 'Date of Birth' },
]

const mapStateToProps = (state) => ({
  ...state.user,
  dob: format(state.user.dob, 'MMMM DD, YYYY'),
  loading: state.register.loading,
  error: state.register.error,
  subscriptions: state.subscription,
})

const mapDispatchToProps = (dispatch) => ({
  onRegister: (code) => dispatch(registerKit(code)),
})

class AccountPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = { regInput: '' }
  }

  render() {
    const {
      loading,
      error,
      onRegister,
      tracking,
      tracking_url,
      connected,
      registered,
      sample_status,
      subscriptions,
      ...userProps
    } = this.props
    const { regInput } = this.state

    return (
      <AppLayout page="account">
        <Box
          flex="grow"
          pad={{ horizontal: 'large', bottom: 'large' }}
          direction="row-responsive"
        >
          <Box
            style={{ maxWidth: '32rem' }}
            basis="1/3"
            margin={{ horizontal: 'medium', bottom: 'large' }}
          >
            <Heading level={2} color="accent-2">
              Profile
            </Heading>
            <Box>
              <Subtitle color="accent-3" weight={600}>
                Name
              </Subtitle>
              <Text color="accent-4">
                {userProps.first_name} {userProps.last_name}
              </Text>
            </Box>
            {FIELDS.map((field) => (
              <Box key={field.id}>
                <Subtitle color="accent-3" weight={600}>
                  {field.label}
                </Subtitle>
                <Text color="accent-4">{userProps[field.id]}</Text>
              </Box>
            ))}
            <Subtitle>SUBSCRIPTIONS</Subtitle>
            <Popup
              trigger={
                <Button>
                  <Text
                    color="accent-1"
                    weight={'bold'}
                    style={{ weight: 600 }}
                  >
                    Manage Subscriptions
                  </Text>
                </Button>
              }
            modal
            >
              {
                close => (
                  <Box direction="column" align="center" justify="center" style={{ padding: 16, borderRadius: 15 }}>
                    <Box direction="row" style={{ marginBottom: 16 }}>
                      <Text>
                        Please email&nbsp;
                      </Text>
                      <a href={"mailto:support@ginihealth.com"}> support@ginihealth.com</a>
                    </Box>
                    <Button
                      label="Okay"
                      color="accent-1"
                      primary
                      onClick={() => {
                        close()
                      }}
                    />
                  </Box>
                )
              }
            </Popup>
          </Box>
          <Box
            style={{ maxWidth: '64rem' }}
            basis="1/2"
            margin={{ horizontal: 'medium', bottom: 'large' }}
            responsive
          >
            <Subtitle size="medium" color="accent-3" weight={600}>
              Your vitamin formula
            </Subtitle>
            <Block>
              <VitaminFormula onboarded={userProps.onboarded2} />
            </Block>
            <Subtitle size="medium" color="accent-3" weight={600}>
              Your DNA data
            </Subtitle>
            <Block>
              <DnaData connected={connected} />
            </Block>
            <Subtitle size="medium" color="accent-3" weight={600}>
              Gini DNA Test Kit
            </Subtitle>
            <TestKits
              sample_status={sample_status}
              registered={registered}
              onRegister={onRegister}
              loading={loading}
              error={error}
              tracking={tracking}
              tracking_url={tracking_url}
              connected={connected}
            />
          </Box>
        </Box>
      </AppLayout>
    )
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountPage)

const Subtitle = ({ children, ...props }) => (
  <Text size="small" color="dark-6" margin={{ vertical: 'medium' }} {...props}>
    {children}
  </Text>
)
