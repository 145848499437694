import React from 'react'
import {  Box, Text, Image } from 'grommet'
import { Link } from 'gatsby'

import logoGini from '../../../images/gini-logo.svg'
import logo23andMe from '../../../images/23andMe.svg'
import logoAncestry from '../../../images/ancestry.svg'
import MyHeritage from '../../../images/myheritage-orange.svg'

const getDnaConnectedBlock = (connected) => {
  switch (connected) {
    case 'myheritage':
      return (
        <>
          <Text
            size="large"
            weight={600}
            margin={{ bottom: 'small' }}
            color="accent-2"
            textAlign="center"
          >
            Genetic data connected.
          </Text>
          <Image
            style={{ height: '1.5rem', flex: '0 1 auto', marginBottom: 16 }}
            src={MyHeritage}
            fit="contain"
          />
        </>
      )
    case '23andme':
      return (
        <>
          <Text
            size="large"
            weight={600}
            margin={{ bottom: 'small' }}
            color="accent-4"
            textAlign="center"
          >
            Genetic data connected.
          </Text>
          <Image
            style={{ height: '2.5rem', flex: '0 1 auto', marginBottom: 16 }}
            src={logo23andMe}
            fit="contain"
          />
        </>
      )
    case 'ancestry':
      return (
        <>
          <Text
            size="large"
            weight={600}
            margin={{ bottom: 'small' }}
            color="accent-4"
            textAlign="center"
          >
            Genetic data connected.
          </Text>
          <Image
            style={{ height: '1.25rem', flex: '0 1 auto', marginBottom: 16 }}
            src={logoAncestry}
            fit="contain"
          />
        </>
      )
    case 'unknown':
      return (
        <>
          <Text size="large" weight={600} color="accent-4" textAlign="center">
            Genetic data connected.
          </Text>
        </>
      )
    case 'gini':
      return (
        <>
          <Text
            size="large"
            weight={600}
            margin={{ bottom: 'small' }}
            color="accent-4"
            textAlign="center"
          >
            Genetic data connected.
          </Text>
          <Image
            style={{ height: '2rem', flex: '0 1 auto', marginBottom: 16 }}
            src={logoGini}
            fit="contain"
          />
        </>
      )
    default:
      return null
  }
}

export default ({ connected }) => (
  <>
    <Link to="/import" style={{ color: 'accent-1' }}>
      <Box style={{ flexDirection: 'column' }}>
        {connected ? (
          getDnaConnectedBlock(connected)
        ) : (
          <>
            <Text
              size="medium"
              color="accent-4"
              textAlign="center"
              weight={600}
              style={{ marginBottom: 16 }}
            >
              No DNA Data Connected
            </Text>
            <Text
              size="small"
              color="accent-3"
              textAlign="center"
              style={{ marginBottom: 16 }}
            >
              Get your vitamin formula personalized to your genetic needs and
              access 30+ DNA reports for free!
            </Text>
          </>
        )}
        <Text
          style={{ textDecoration: 'underline', marginBottom: 16 }}
          color="accent-1"
          size="medium"
          textAlign="center"
          weight={600}
        >
          Upload Your DNA File
        </Text>
        <Text size="small" color="accent-2" textAlign="center">
          *For Ancestry, 23andMe and MyHeritage Tests Only
        </Text>
      </Box>
    </Link>
  </>
)
