import React, { useState } from 'react'
import { Button, Box, Text, Anchor, Heading, TextInput } from 'grommet'
import { Block, HalfBlock } from './blocks'
import colours from '../../../util/colours'
import { Checkmark } from 'grommet-icons'
const BUY_NOW_LINK =
  'https://www.kickstarter.com/projects/ginihealth/personalized-health-and-nutrition-coach-powered-by'

export default ({ sample_status, registered, onRegister, loading, error, connected, tracking, tracking_url }) => {
  const [regInput, setRegInput] = useState('')
  return (
    <>
      <Box direction="row-responsive" justify="start" wrap>
        {getBuyKitBlock(sample_status)}
        {registerKitBox(
          registered,
          regInput,
          (evt) => setRegInput(evt.target.value),
          () => onRegister(regInput),
          loading,
          error
        )}
      </Box>
      {connected !== 'gini' &&
        getDnaStatusBlock(sample_status, tracking, tracking_url)}
    </>
  )
}
const getBuyKitBlock = (status) =>
  !status ? (
    <HalfBlock background={colours.WHITE} justify="space-between">
      <Text
        size="large"
        margin={{ vertical: 'xsmall' }}
        color="accent-4"
        textAlign="center"
        weight={600}
      >
        Gini DNA Test Kit
      </Text>
      <Box direction="row" margin={{ bottom: 'small' }}>
        <Text size="xsmall" color="accent-4">
          Not DNA Tested and interested in your full genetic potential? Access
          all 50+ Gini DNA reports and get your vitamin formula adjusted to your
          genetic needs.
        </Text>
      </Box>
      <Button label="Learn More" href={'https://shop.ginihealth.com/collections/frontpage/products/gini-dna-test-kit'} color="accent-1" primary />
    </HalfBlock>
  ) : null

const registerKitBox = (
  registered,
  regInput = '',
  onChange,
  onSubmit,
  loading,
  error
) => {
  const Instructions = () => (
    <Anchor
      href={'https://ginihealth.com/instructions'}
      style={{ color: 'accent-1' }}
      target="_blank"
    >
      <Text
        style={{ textDecoration: 'underline' }}
        color="accent-1"
        textAlign="center"
        size="small"
      >
        DNA Test Kit Instructions
      </Text>
    </Anchor>
  )
  return registered ? (
    <HalfBlock>
      <Heading level={3} color="accent-1">
        kit registered
      </Heading>
      <Checkmark size="large" color="status-ok" />
      <Instructions />
    </HalfBlock>
  ) : (
    <HalfBlock>
      <Text
        size="large"
        margin={{ vertical: 'xsmall' }}
        color="accent-4"
        textAlign="center"
        weight={600}
      >
        Register your kit
      </Text>
      <Text size="small" color="accent-2" margin={{ bottom: 'small' }}>
        Enter your kit barcode.
      </Text>
      <Box direction="column" gap="medium" align="center">
        <Box
          style={{
            maxWidth: '10rem',
            width: '100%',
          }}
        >
          <TextInput
            disabled={!!loading}
            onChange={onChange}
            value={regInput}
            style={{
              borderColor: colours['accent-1'],
              opacity: !loading ? 1 : 0.5,
            }}
            placeholder="X00000000"
          />
        </Box>
        <Button
          label={!loading ? 'Register' : 'Checking...'}
          disabled={!!loading}
          onClick={!loading ? onSubmit : null}
          color="accent-1"
          primary
        />
      </Box>
      {error && (
        <Box pad={{ top: 'medium' }}>
          <Text size="small" color="status-error">
            Invalid code entered
          </Text>
        </Box>
      )}
      <Instructions />
    </HalfBlock>
  )
}

const getDnaStatusBlock = (status, tracking, tracking_url) => {
  switch (status) {
    case 'ordered':
      return (
        <Block>
          <Heading level={3} color="accent-1">
            kit ordered
          </Heading>
          <Text size="small" color="accent-2" textAlign="center">
            We received your order and we are preparing to ship out your kit.
          </Text>
        </Block>
      )
    case 'shipped':
      return (
        <Block>
          <Heading level={3} color="accent-1">
            kit shipped
          </Heading>
          <Text
            size="small"
            color="accent-2"
            textAlign="center"
            margin={{ bottom: 'small' }}
            textAlign="center"
          >
            Your Gini DNA testing kit has shipped from the facility.
          </Text>
          <Anchor href={tracking_url} style={{ color: 'accent-1' }}>
            <Text
              style={{ textDecoration: 'underline' }}
              color="accent-2"
              textAlign="center"
            >
              <Text weight="bold" color="accent-2">
                tracking:&nbsp;
              </Text>
              {tracking}
            </Text>
          </Anchor>
        </Block>
      )
    case 'received':
      return (
        <Block>
          <Heading level={3} color="accent-1">
            sample received
          </Heading>
          <Text size="small" color="accent-2" textAlign="center">
            The lab has received you sample and will begin testing soon.
          </Text>
        </Block>
      )
    case 'processing':
      return (
        <Block>
          <Heading level={3} color="accent-1">
            test in progress
          </Heading>
          <Text size="small" color="accent-2" textAlign="center">
            The lab is processing your DNA sample.
          </Text>
        </Block>
      )
    default:
      return null
  }
}
